import React from "react";
import "./coming.scss";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import logo from "../../images/logo.png";
import image from "../../images/background.png";

const Coming = () => {
  return (
    <div className="coming">
      <div className="top"></div>
      <div className="left">
        <img className="logo" src={logo} alt="" />

        <div className="container">
          <div>
            <p className="title1">For any information</p>

            <a href="mailto:contact@ogsp.group?subject = Feedback&body = Message">
              Contact Us
            </a>
          </div>
        </div>
      </div>
      <div className="right">
        <img className="image" src={image} alt="" />
      </div>
    </div>
  );
};

export default Coming;
